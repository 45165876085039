import Rails from "rails-ujs";
import Turbolinks from "turbolinks";
import "bootstrap";
import "../stylesheets/application.scss";
import "bootstrap-select";
import "bootstrap-select/dist/css/bootstrap-select.min.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import $ from "jquery";

require.context("../images", true);

Rails.start();
Turbolinks.start(); // Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// SELECT PICKER OVERRIDE DEFAULT
$.fn.selectpicker.Constructor.DEFAULTS.style = "btn-outline-secondary";

import "src/admins/sidebar";
import "src/numpad";
import "utils/turbolinks-integration";
