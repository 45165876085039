import React, { Component } from 'react'
import QrReader from 'react-qr-reader'

class EmployeeQrScanner extends Component {
  state = {
    result: 'No result',
    cameraOn: true,
    facingMode: 'user'
  }

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  toggleFacingMode = () => {
    const { facingMode } = this.state;
    if(facingMode === "environment") {
      this.setState({facingMode: "user"})
    } else {
      this.setState({facingMode: "environment"})
    }
  }

  handleScan = data => {
    if (data) {
      const self = this;
      self.setState({
        result: 'QR Detected. Searching database...',
        employee: null
      })

      fetch(`/employee_search?code=${data}`)
        .then((resp) => {
          return resp.json()
        })
        .then((json) => {
          self.setState(
            {employee: json.data, result: 'Employee found!'},
            () => self.inputRef.current.form.submit()
          )
        }).catch((error) => {
          self.setState({result: 'Employee not found'})
        })
    }
  }
  handleError = err => {
    console.error(err)
  }
  render() {
    const { cameraOn, result, employee, facingMode } = this.state;
    return (
      <div className="mx-auto">
        { 
          cameraOn &&

          <QrReader
            className="mx-auto"
            delay={300}
            facingMode={facingMode}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: '320px' }}
          />
        }
        
        <p>{result}</p>
        {
          employee &&
          <div>
            <p>{employee.attributes.username}</p>
            <input ref={this.inputRef} type="hidden" name="assembly[employee_id]" value={employee.id}/>
          </div>
        }
        <div className="display-flex justify-content-around">
          {
            cameraOn ? 
              <button
                onClick={(e) => this.setState({cameraOn: false})}
                type="button"
                className="btn btn-primary">
                Off Reader
              </button>
              :
              <button
                onClick={(e) => this.setState({cameraOn: true})}
                type="button"
                className="btn btn-primary">
                On Reader
              </button>
          }

          {
            <button
              onClick={(e) => this.toggleFacingMode()}
              type="button"
              className="btn btn-primary ml-3">
              Toggle facing
            </button>
          }
        </div>
        
      </div>
    )
  }
}

export default EmployeeQrScanner;