import $ from 'jquery';

$(document).on('click', '#sidebarCollapse', function(){
  $('#sidebar').toggleClass('active');
})

function expand_submenu(href){
  $('a[href$="#' + href + '"]').attr("aria-expanded", "true");
  $("#" + href).addClass("show");
}

$(document).on("turbolinks:load", function(){
  let path = window.location.pathname.split('/')[2];
  switch (path) {
    case "employees":
      expand_submenu('creditPackSubmenu');
      break;
    case "stock_adjustments":
      expand_submenu('inventorySubmenu');
      expand_submenu('transactionsSubmenu');
      break;
    case "products":
    case "stock_on_hand":
      expand_submenu('inventorySubmenu');
      expand_submenu('productSubmenu');
      break;
    case "bill_of_materials":
      expand_submenu("bill_of_materials-submenu");
      break;
    case "work_stations":
      expand_submenu("work_stations-submenu");
      break;
    case "unit_of_measures":
    case "customers":
      expand_submenu("settingsSubmenu");
      break;
    case "reports":
      expand_submenu("reportsSubmenu");
      break;
  }
})
