import React, { Fragment, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const ProductTypeahead = ({
  defaultInputValue,
  hiddenInputName,
  labelTitle,
  showMainWarehouseQuantity,
  selectedId: selectedIdProps,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedId, setSelectedId] = useState(selectedIdProps || "");

  const makeAndHandleRequest = (query) => {
    return fetch(`/products.json?filter[search]=${query}`)
      .then((resp) => {
        return resp.json();
      })
      .then((json) => {
        const options = json.data.map((i) => {
          return { ...i.attributes, ...{ id: i.id } };
        });
        return { options };
      });
  };

  const handleSearch = (query) => {
    setLoading(true);
    makeAndHandleRequest(query).then(({ options }) => {
      setOptions(options);
      setLoading(false);
    });
  };

  const handleChange = (events) => {
    if (events[0]) {
      setSelectedId(events[0].id);
      setSelectedProduct(events[0]);
    } else {
      setSelectedId("");
    }
  };

  const handleInputChange = (e) => {
    if (e === "") {
      setSelectedId("");
    }
  };

  return (
    <Fragment>
      {labelTitle && (
        <label className="form-control-label">
          {labelTitle}{" "}
          {showMainWarehouseQuantity && selectedProduct
            ? `- Main (${selectedProduct.warehouse_quantity})`
            : null}
        </label>
      )}
      <AsyncTypeahead
        filterBy={() => true}
        options={options}
        isLoading={isLoading}
        multiple={false}
        allowNew={false}
        labelKey={(option) => `${option.code} - ${option.description}`}
        minLength={1}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onSearch={handleSearch}
        placeholder="Search for a product..."
        defaultInputValue={defaultInputValue || ""}
        renderMenuItemChildren={(option, props) => (
          <div>
            {option.code} - {option.description}
          </div>
        )}
      />
      <input type="hidden" name={hiddenInputName} value={selectedId} />
    </Fragment>
  );
};

export default ProductTypeahead;
