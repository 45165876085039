import $ from "jquery";

$(document).on("click", "#numpad .btn-number", function () {
  var currentNumber = $("#numpad-input").val();
  var newNumber = currentNumber + $(this).text();
  $("#numpad-input").val(parseInt(newNumber));
});

$(document).on("click", "#numpad .btn-clr", function () {
  $("#numpad-input").val("0");
});
